import { Observable } from './vendor/observable.js';

function init() {
    'use strict';

    /** @type {HTMLElement | null} */
    const fileSelect = document.getElementById("fileSelect");
    /** @type {HTMLElement | null} */
    const videoIn = document.getElementById("video-input");
    /** @type {HTMLElement | null} */
    const videoOut = document.getElementById("video-output");
    /** @type {HTMLElement | null} */
    const cropBtn = document.getElementById("crop-btn");
    /** @type {HTMLElement | null} */
    const areaElt = document.getElementById("area");
    /** @type {HTMLElement | null} */
    const loader = document.getElementById("loader");
    /** @type {HTMLElement | null} */
    const progress = document.getElementById("progress");
    /** @type {HTMLElement | null} */
    const errorMsg = document.getElementById("error-msg");
    /** @type {HTMLElement | null} */
    const downloadBtn = document.getElementById("download-btn");
    /** @type {HTMLElement | null} */
    const areaTxt = document.getElementById("area-txt");
    /** @type {HTMLElement | null} */
    const startTimeInput = document.getElementById("start-time");
    /** @type {HTMLElement | null} */
    const endTimeInput = document.getElementById("end-time");
    /** @type {HTMLElement | null} */
    const scaleInput = document.getElementById("scale");
    /** @type {HTMLElement | null} */
    const outDim = document.getElementById("out-dim");
    /** @type {HTMLElement | null} */
    const resultsWrapper = document.getElementById('results-wrapper');
    /** @type {HTMLElement | null} */
    const toolbarWrapper = document.getElementById('toolbar-wrapper');

    // Firefox stores form inputs by default, so we have to manually reset them.
    document.querySelector('form').reset();

    let errorTimer;
    /**
     * @param {string} msg error msg to show below input
     */
    function showError(msg) {
        const ERROR_DURATION = 5100; // 5.1 seconds

        clearError();
        errorMsg.textContent = msg;
        errorMsg.classList.remove("hidden");
        setTimeout(() => errorMsg.classList.add("hidden"), 0);
        errorTimer = setTimeout(clearError, ERROR_DURATION);
    }

    /** Clears the currently displayed error */
    function clearError() {
        clearTimeout(errorTimer);
        errorTimer = undefined;
        errorMsg.textContent = "\n";
    }

    new ResizeObserver(() => {
        const width = areaElt.offsetWidth;
        const height = areaElt.offsetHeight;
        areaTxt.textContent = `${width} x ${height}`;

        const [outWidth, outHeight] = calcDimensions(width, height, scaleInput.valueAsNumber);

        outDim.textContent = `Output Dimensions: ${outWidth} x ${outHeight}`;
        outDim.classList.remove('hidden');
    }).observe(areaElt);

    const { createFFmpeg, fetchFile } = FFmpeg;

    let ffmpeg;
    function initFFmpeg() {
        ffmpeg = createFFmpeg({
            // corePath: "./ffmpeg-core/ffmpeg-core.js",
            corePath: "https://cdn.jsdelivr.net/npm/@ffmpeg/core@0.10.0/dist/ffmpeg-core.js",
            progress: ({ ratio }) => {
                const clampedRatio = clamp(0, ratio, 100);
                progress.textContent = `${(clampedRatio * 100).toFixed(2)}%`;
            },
            log: false
        });

        return ffmpeg;
    }
    initFFmpeg();

    /**
     * Calculates new dimensions using some scale factor.
     * @param {number} width
     * @param {number} height
     * @param {number} scale
     * @returns
     */
    function calcDimensions(width, height, scale) {
        const outWidth = Math.floor((width * scale) / 2) * 2;
        const outHeight = Math.floor((height * scale) / 2) * 2;

        return [outWidth, outHeight];
    }

    /**
     * @param {File} file
     * @param {number} x
     * @param {number} y
     * @param {number} w
     * @param {number} h
     * @param {number} start
     * @param {number} end
     * @param {number} scaleFactor
     */
    const transcode = async (file, x, y, w, h, start, end, scaleFactor) => {
        if (!ffmpeg.isLoaded()) {
            await ffmpeg.load();
        }

        // Scaling code
        const [outWidth, outHeight] = calcDimensions(w, h, scaleFactor);

        // Sanitize the file name.
        let inputName = file.name.replace(/\W/i, '-');
        inputName = inputName.replace(/\-{2,}/, '-');

        await ffmpeg.FS("writeFile", inputName, await fetchFile(file));

        const outputName = 'output.mp4';

        await ffmpeg.run(
            "-i",
            inputName,
            "-ss",
            `${start}`,
            "-to",
            `${end}`,
            "-vf",
            `crop=${w}:${h}:${x}:${y}, scale=${outWidth}:${outHeight}`, // for scaling
            outputName,
        );

        const data = ffmpeg.FS("readFile", outputName);

        if (videoOut.src) {
            URL.revokeObjectURL(videoOut.src);
        }

        videoOut.onresize = () => {
            videoOut.style.display = "inline-block";
            videoOut.scrollIntoView({ behavior: "smooth" });
        };

        const objURL = URL.createObjectURL(new Blob([data.buffer], { type: "video/mp4" }));
        videoOut.src = objURL;

        await fetch(objURL)
            .then((res) => res.blob())
            .then((blob) => {
                const downloadURL = window.URL.createObjectURL(blob);

                downloadBtn.dataset.href = downloadURL;
                downloadBtn.onclick = () => {
                    var a = document.createElement("a");
                    a.href = downloadBtn.dataset.href;
                    a.download = inputName;
                    document.body.appendChild(a);
                    a.click();
                    URL.revokeObjectURL(a.href);
                };
            })
            .catch((err) => console.error("Something went fetching the video at", objURL, err));
    };

    fileSelect.addEventListener("input", async () => {
        const file = fileSelect.files[0];
        if (!file || !file.type.startsWith("video")) {
            videoIn.src = null;
            cropBtn.disabled = true;
            areaElt.style.display = "none";

            return;
        }

        const fr = new FileReader();
        fr.onload = () => {
            videoIn.textContent = "";
            videoIn.src = URL.createObjectURL(new Blob([fr.result], { type: "video/mp4" }));

            toolbarWrapper?.classList.remove('hidden');

            videoIn.onresize = () => {
                areaElt.style.display = "flex";

                createDragElement(areaElt, videoIn.clientWidth, videoIn.clientHeight);

                progress.textContent = "";
                cropBtn.disabled = false;
                cropBtn.textContent = "Crop";

                const duration = Math.floor(videoIn.duration * 100) / 100;
                startTimeInput.disabled = false;
                startTimeInput.max = toRange(duration - 0.01);
                startTimeInput.value = "0";
                startTimeInput.title = formatNumber2Time(0);

                endTimeInput.disabled = false;
                endTimeInput.value = toRange(duration);
                endTimeInput.min = toRange(0.01);
                endTimeInput.max = toRange(duration);
                endTimeInput.title = formatNumber2Time(duration);

                scaleInput.disabled = false;
                scaleInput.value = "1";
                scaleInput.min = toRange(0.1);

                function isValidStart() {
                    const curValue = startTimeInput.valueAsNumber;
                    return curValue < prevEnd && curValue >= 0;
                }

                function isValidEnd() {
                    const curValue = endTimeInput.valueAsNumber;
                    return curValue > prevStart && curValue <= duration;
                }

                let prevStart = 0;
                startTimeInput.oninput = () => {
                    if (isValidStart()) {
                        const newValue = startTimeInput.valueAsNumber;
                        prevStart = newValue;
                        endTimeInput.min = toRange(newValue + 0.01);
                        startTimeInput.title = formatNumber2Time(newValue);
                    }
                };
                startTimeInput.onblur = () => {
                    if (!isValidStart()) {
                        startTimeInput.valueAsNumber = prevStart;
                    }
                };

                let prevEnd = duration;
                endTimeInput.oninput = () => {
                    if (isValidEnd()) {
                        const newValue = endTimeInput.valueAsNumber;
                        prevEnd = newValue;
                        startTimeInput.max = toRange(newValue - 0.01);
                        endTimeInput.title = formatNumber2Time(newValue);
                    }
                };

                endTimeInput.onblur = () => {
                    if (!isValidEnd()) {
                        endTimeInput.valueAsNumber = prevEnd;
                    }
                };

                let prevScale = 1;
                scaleInput.oninput = () => {
                    const newValue = scaleInput.valueAsNumber;
                    if (newValue > 0) {
                        prevScale = newValue;
                    }

                    const width = areaElt.offsetWidth;
                    const height = areaElt.offsetHeight;
                    const [outWidth, outHeight] = calcDimensions(width, height, newValue);
                    outDim.textContent = `Output Dimensions: ${outWidth} x ${outHeight}`;
                };

                scaleInput.onblur = () => {
                    const newValue = scaleInput.valueAsNumber;
                    if (!newValue || newValue < 0) {
                        scaleInput.valueAsNumber = 0.1;
                    }

                    const width = areaElt.offsetWidth;
                    const height = areaElt.offsetHeight;
                    const [outWidth, outHeight] = calcDimensions(
                        width,
                        height,
                        scaleInput.valueAsNumber,
                    );

                    outDim.textContent = `Output Dimensions: ${outWidth} x ${outHeight}`;
                };

                function cancel() {
                    try {
                        ffmpeg.exit();
                    } finally {
                        progress.textContent = "Canceled";
                        cropBtn.textContent = "Crop";
                        cropBtn.style.background = "";
                        cropBtn.onclick = crop;
                        loader.style.display = "none";

                        initFFmpeg();
                    }
                }
                async function crop() {
                    progress.textContent = "0.00%";
                    cropBtn.textContent = "Cancel";
                    cropBtn.style.background = "#ef3746";
                    cropBtn.onclick = cancel;
                    loader.style.display = "inline-block";

                    try {
                        let ogWidth = videoIn.videoWidth;
                        let ogHeight = videoIn.videoHeight;

                        let curWidth = videoIn.clientWidth;
                        let curHeight = videoIn.clientHeight;

                        let xFactor = ogWidth / curWidth;
                        let yFactor = ogHeight / curHeight;

                        await transcode(
                            file,
                            (areaElt.offsetLeft * xFactor),
                            (areaElt.offsetTop * yFactor),
                            (areaElt.offsetWidth * xFactor),
                            (areaElt.offsetHeight * yFactor),
                            startTimeInput.valueAsNumber || 0,
                            endTimeInput.valueAsNumber || duration,
                            scaleInput.valueAsNumber || 1,
                        );
                    } catch (err) {
                        progress.textContent = "Errored";
                        console.error(err);
                    } finally {
                        loader.style.display = "none";

                        cropBtn.textContent = "Crop";
                        cropBtn.style.background = "";
                        cropBtn.onclick = crop;

                        resultsWrapper.classList.remove('hidden');
                    }
                }

                cropBtn.onclick = crop;
            };

            window.onresize = () => {
                createDragElement(areaElt, videoIn.clientWidth, videoIn.clientHeight);
            };
        };

        fr.readAsArrayBuffer(file);
    });

    /**
     * @callback moveArea
     * @param {HTMLElement} el
     * @param {number} dx
     * @param {number} dy
     */

    /** @type {moveArea} */
    let moveArea;

    /**
     * Clamps a value within a range
     * @param {number} min
     * @param {number} val
     * @param {number} max
     * @returns
     */
    function clamp(min = 0, val = 0, max = Infinity) {
        return val < min ? min : val > max ? max : val;
    }

    /**
     * Creates a draggable element.
     * @param {HTMLDivElement} el
     * @param {number} cWidth the container's width
     * @param {number} cHeight the container's height
     */
    function createDragElement(el, cWidth, cHeight) {
        var deltaX = 0,
            deltaY = 0,
            lastX = 0,
            lastY = 0;

        // Bind to the start of the drag events.
        el.onmousedown = mouseDown;
        el.ontouchstart = mouseDown;

        // Center the draggable element
        let left = (cWidth / 2) - (el.clientWidth / 2);
        let top = (cHeight / 2) - (el.clientHeight / 2);

        let maxWidth = cWidth - el.offsetLeft;
        let maxHeight = cHeight - el.offsetTop;

        el.style.left = `${left}px`;
        el.style.top = `${top}px`;
        el.style.maxWidth = `${maxWidth}px`;
        el.style.maxHeight = `${maxHeight}px`;

        /**
         *
         * @param {MouseEvent} e
         */
        function mouseDown(e) {
            e = e || window.event;

            const padding = 20; // pixels
            let offset = getOffset(e);

            // Checks that mousedown or touchstart is inside the resize corner padding for the element
            if ((offset.offsetX > el.offsetWidth - padding) && (offset.offsetY > el.offsetHeight - padding)) {
                // We have to unset these handers or else mobile devices freak out.
                document.onmousemove = null;
                document.onmouseup = null;
                document.ontouchmove = null;
                document.ontouchend = null;

                return;
            }

            e.preventDefault();

            // Get the mouse cursor or touch point position at startup
            let coordinates = getCoordinates(e);
            lastX = coordinates.clientX;
            lastY = coordinates.clientY;
            el.focus();

            // call a function whenever the cursor moves
            document.onmousemove = dragElement;
            document.onmouseup = closeDragElement;

            document.ontouchmove = dragElement;
            document.ontouchend = closeDragElement;

            return false;
        }

        moveArea = (el, dx, dy) => {
            // Set the element's new position:
            el.style.top = clamp(0, el.offsetTop + dy, cHeight - el.offsetHeight) + "px";
            el.style.left = clamp(0, el.offsetLeft + dx, cWidth - el.offsetWidth) + "px";
        };

        /**
         *
         * @param {MouseEvent | TouchEvent} e
         */
        function dragElement(e) {
            e = e || window.event;

            if (window.MouseEvent && e instanceof MouseEvent) {
                e.preventDefault();
            }

            let coordinates = getCoordinates(e);

            // calculate the new cursor position
            deltaX = coordinates.clientX - lastX;
            deltaY = coordinates.clientY - lastY;
            lastX = coordinates.clientX;
            lastY = coordinates.clientY;

            // set the element's new position
            moveArea(el, deltaX, deltaY);

            return true;
        }

        /**
         * Retrieve the cursor or touch coordinates.
         *
         * @param {MouseEvent | TouchEvent} e
         * @return {object}
         */
        function getCoordinates(e) {
            e = e || window.event;

            if (window.TouchEvent && e instanceof TouchEvent) {
                let touch = e.touches[0];

                return {
                    clientX: touch.clientX,
                    clientY: touch.clientY
                };
            }

            return {
                clientX: e.clientX,
                clientY: e.clientY
            };
        }

        /**
         * Retrieve the cursor or touch offset.
         *
         * @param {MouseEvent | TouchEvent} e
         * @return {object}
         */
        function getOffset(e) {
            e = e || window.event;

            if (window.TouchEvent && e instanceof TouchEvent) {
                let touch = e.touches[0];
                let brc = e.target.getBoundingClientRect();

                return {
                    offsetX: touch.clientX - brc.x,
                    offsetY: touch.clientY - brc.y
                };
            }

            return {
                offsetX: e.offsetX,
                offsetY: e.offsetY
            };
        }

        function closeDragElement() {
            // Stop moving when mouse button is released
            document.onmouseup = null;
            document.onmousemove = null;
        }
    }

    /**
     * Disable default drag over events.
     */
    window.addEventListener("dragover", (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        return false;
    });

    /**
     * Add support for drag and drop.
     */
    window.addEventListener("drop", (e) => {
        if (e && e.preventDefault) {
            e.preventDefault();
        }

        if (e.dataTransfer == null) {
            return showError('Invalid data transfer.');
        }

        const files = e.dataTransfer.files;
        if (files.length !== 1) {
            return showError("Only drop one file at a time.");
        }

        const file = files.item(0);
        if (file === null) {
            return showError('Unable to load file.');
        }

        if (!file.type.startsWith("video")) {
            return showError(`Did not recognize "${file.name}" as a video file.`);
        }

        clearError();

        fileSelect.files = files;
        fileSelect.dispatchEvent(new InputEvent("input"));

        return false;
    });

    /**
     *
     * @param {KeyboardEvent} event
     */
    function handleAreaKeyBindings(event) {
        let shift = 1;
        if (event.metaKey) {
            shift = 10;
        }

        if (event.shiftKey && event.metaKey) {
            shift = 25;
        }

        if (event.ctrlKey) {
            if (event.key === "ArrowUp") {
                event.preventDefault();
                areaElt.style.height = areaElt.offsetHeight - shift;
            } else if (event.key === "ArrowRight") {
                event.preventDefault();
                areaElt.style.width = areaElt.offsetWidth + shift;
            } else if (event.key === "ArrowDown") {
                event.preventDefault();
                areaElt.style.height = areaElt.offsetHeight + shift;
            } else if (event.key === "ArrowLeft") {
                event.preventDefault();
                areaElt.style.width = areaElt.offsetWidth - shift;
            }
        } else {
            if (event.key === "ArrowUp") {
                event.preventDefault();
                moveArea(areaElt, 0, -shift);
            } else if (event.key === "ArrowRight") {
                event.preventDefault();
                moveArea(areaElt, shift, 0);
            } else if (event.key === "ArrowDown") {
                event.preventDefault();
                moveArea(areaElt, 0, shift);
            } else if (event.key === "ArrowLeft") {
                event.preventDefault();
                moveArea(areaElt, -shift, 0);
            }
        }
    }

    /**
     * Add keyboard support.
     */
    areaElt.onfocus = () => {
        areaElt.onkeydown = handleAreaKeyBindings;
    };

    /**
     * Remove event listeners when the area does not have focus.
     */
    areaElt.onblur = () => {
        areaElt.onkeydown = null;
    };
}

/**
 * Formats a number as a time
 * @param {number} num
 * @returns {number}
 */
function formatNumber2Time(num) {
    var hours = Math.floor(num / 3600);
    var minutes = Math.floor((num - hours * 3600) / 60);
    var seconds = (num - hours * 3600 - minutes * 60).toFixed(2);

    if (hours == 0) {
        hours = "";
    } else if (hours < 10) {
        hours = "0" + hours + ":";
    }

    if (minutes < 10) {
        minutes = "0" + minutes;
    }
    if (seconds < 10) {
        seconds = "0" + seconds;
    }

    return hours + minutes + ":" + seconds;
}

/**
 * Converts a number to a range representation.
 * To be used in min and max attributes of input fields.
 * @param {number} num
 * @returns {string}
 */
function toRange(num) {
    return num.toFixed(2);
}

function ready(fn) {
    if (document.readyState != 'loading') {
        fn();
    } else {
        document.addEventListener('DOMContentLoaded', fn);
    }
}

ready(init);

ready(() => {
    const bindings = {};
    const app = () => {
        bindings.test = new Observable('Boom!');

        applyBindings();
    };

    const bindValue = (input, obs) => {
        // Set the initial value
        input.value = obs.value;

        // This will update the input's value whenever the observed value changes
        obs.subscribe(() => input.value = obs.value);
        input.onkeyup = () => obs.value = input.value;
    };

    const bindDisplay = (el, obs) => {
        // Set the initial value
        el.innerText = obs.value;

        // This will update the element whenever the observed value changes
        obs.subscribe(() => el.innerText = obs.value);
    };

    const bindHtml = (el, obs) => {
        // Set the initial value
        el.innerHTML = obs.value;

        // This will update the element whenever the observed value changes
        obs.subscribe(() => el.innerHTML = obs.value);
    };

    const bindEval = (el, obs) => {
        const template = el.dataset.pattern;
        const regex = /(?:{{(.*)}})/m;

        const matches = regex.exec(template);
        if (matches === null) {
            return;
        }

        // Set the initial value
        el.innerText = template.replace(matches[0], obs.value);

        // This will update the element whenever the observed value changes
        obs.subscribe(() => {
            el.innerText = template.replace(matches[0], obs.value);
        });
    };

    const evaluateInContext = (str, ctx) => {
        return (new Function(`with(this) { return ${str}; }`)).call(ctx);
    };

    const applyBindings = () => {
        document.querySelectorAll('[data-bind]').forEach(el => {
            const observable = bindings[el.getAttribute('data-bind')];
            bindValue(el, observable);
        });

        document.querySelectorAll('[data-display]').forEach(el => {
            const observable = bindings[el.getAttribute('data-display')];
            bindDisplay(el, observable);
        });

        document.querySelectorAll('[data-html]').forEach(el => {
            const observable = bindings[el.getAttribute('data-html')];
            bindHtml(el, observable);
        });

        document.querySelectorAll('[data-eval]').forEach(el => {
            let target = el.getAttribute('data-eval');
            if (!target) {
                const regex = /(?:{{(.*)}})/m;

                const matches = regex.exec(el.innerText);
                if (matches === null) {
                    return;
                }

                target = matches[1];
            }

            const observable = bindings[target];
            if (!observable) {
                return;
            }

            el.dataset.pattern = el.innerText;

            bindEval(el, observable);
        });
    };

    // Set a timeout of 0 to allow the initial rendering cycle to complete.
    setTimeout(app, 0);
});